import React, { Component } from "react";
/* We want to import our 'AuthHelperMethods' component in order to send a login request */

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Select } from "@material-ui/core";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import DirectionsCarFilledIcon from '@mui/icons-material/DirectionsCarFilled';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import PersonIcon from '@mui/icons-material/Person';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import MapIcon from '@mui/icons-material/Map';
import BookOnlineIcon from '@mui/icons-material/BookOnline';
import CarRentalIcon from '@mui/icons-material/CarRental';

class LoginWebsite extends Component {

    /* In order to utilize our authentication methods within the AuthService class, we want to instantiate a new object */
    constructor(props) {
        super(props);
        this.state = {
            
        };
    }
    
   
    componentWillMount() {
        /* Here is a great place to redirect someone who is already logged in to the protected route */
       
    }

    render() {
        return (
            <React.Fragment>
                <Backdrop className="backDropLoader" open={this.state.isLoaderOpen} >
                    <CircularProgress color="inherit" />
                </Backdrop>
                
                <section className="hero-wrap hero-wrap-2 js-fullheight" id="backgroundSectionImg" data-stellar-background-ratio="0.5">
                    <div className="overlay"></div>
                    <div className="container">
                    <div className="row no-gutters slider-text js-fullheight align-items-end justify-content-start" style={{height:'395px'}}>
                    <div className="col-md-9 ftco-animate pb-5 fadeInUp ftco-animated">
                    <p className="breadcrumbs"><span className="mr-2"><a href="index.html">Home <i className="ion-ios-arrow-forward"></i></a></span> <span>Login <i className="ion-ios-arrow-forward"></i></span></p>
                    <h1 className="mb-3 bread">Login</h1>
                    </div>
                    </div>
                    </div>
                </section>

                

         <div class="container login-container" id="twoLoginPage">
            <div class="row">
                <div class="col-md-6 login-form-1">
                    <h3>Login as Customer</h3>
                    <form>
                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="Your Email *" value="" />
                        </div>
                        <div class="form-group">
                            <input type="password" class="form-control" placeholder="Your Password *" value="" />
                        </div>
                        <div class="form-group">
                            <input type="submit" class="btnSubmit" value="Login" />
                        </div>
                        <div class="form-group">
                            <a href="#" class="ForgetPwd">Signup</a>
                            <a href="#" class="ForgetPwd" style={{float:'right'}}>Forget Password?</a>
                        </div>
                    </form>
                </div>
                <div class="col-md-6 login-form-2">
                    <h3>Login as Host</h3>
                    <form>
                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="Your Email *" value="" />
                        </div>
                        <div class="form-group">
                            <input type="password" class="form-control" placeholder="Your Password *" value="" />
                        </div>
                        <div class="form-group">
                            <input type="submit" class="btnSubmit" value="Login" />
                        </div>
                        <div class="form-group">
                            <a href="#" class="ForgetPwd" value="Login">Become a Host</a>
                            <a href="#" class="ForgetPwd" value="Login" style={{float:'right'}}>Forget Password?</a>
                        </div>
                    </form>
                </div>
            </div>
        </div>             
                

               
                
            </React.Fragment>
        );
    }

}

export default LoginWebsite;