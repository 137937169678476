import React, { useEffect, useState } from "react";
import "antd/dist/antd.css";
import { Row, Col, Dropdown, Menu, Divider } from "antd";
import {
    ShoppingCartOutlined,
    DownOutlined,
} from "@ant-design/icons";
import { Button } from "@material-ui/core";

//class Cart extends Component {
function Cart(props) {

    const [productList, setProductList] = useState(props.cartList && props.cartList.length != 0 ? props.cartList : JSON.parse(localStorage.getItem('itemAddedToCart')) );
    
    const [totalCost, setTotalCost] = useState(0);

    useEffect(() => {
        window.addEventListener('storage', () => {
            // When local storage changes, dump the list to
            // the console.
          });
    }, [])

    useEffect(() => {
        let fff = props.cartList;
        //setProductList(fff)
        if(props.cartList && props.cartList.length !== 0){
            setProductList(props.cartList)
        }
    }, [props.cartList])

    

   const clearCartItems = ()=>{
    localStorage.setItem('itemAddedToCart', JSON.stringify([]));
    window.location.reload()
   }


    const cartProductList = () => {
        let _productList = productList;
        if(_productList){
            
        _productList = _productList.sort(function (a, b) {
            return b.id - a.id;
        });

        let productPriceArray = [];
        _productList.map(item =>{
            let productPrice = item.quantity * item.product_cost;
            productPriceArray.push(productPrice);
        });
        setTotalCost(productPriceArray.reduce((a, b) => a + b, 0));
        
        let totalProdCostt = productPriceArray.reduce((a, b) => a + b, 0);
        localStorage.setItem('totalProdCost', totalProdCostt);

        return (
            <Menu className="">
                {_productList.map((item, idx) => {
                    return (
                        (idx === 0 || idx === 1 || idx === 2) && (
                            <Menu.Item key={idx}>
                                <div>
                                    <Row gutter={2}>
                                        <Col>
                                            <img
                                                width="100px"
                                                style={{width:'70px', height:'90px'}}
                                                src={item.images_uploaded[0].images}
                                            />
                                        </Col>
                                        <Col style={{marginLeft:'15px'}}>
                                            <div
                                                className="cartProductList"
                                                style={{ color: "green" }}
                                            >
                                                {item.product_name}
                                            </div>
                                            <div>
                                            Quantity - {item.quantity}
                                            </div>
                                            <div>
                                            Size - {item.size}
                                            </div>
                                            <div>
                                            Price -  ${item.quantity * item.product_cost}
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <Divider className="cartDividerHorizontal" />
                            </Menu.Item>
                        )
                    );
                })}
                <Menu.Item key="cartDetails">
                    <Row gutter={20}>
                        <Col span={11}>
                            <div style={{ whiteSpace: "normal" }}>
                                {
                                _productList.length - 3 < 0 ? 0 : _productList.length - 3 }{' '}
                                more items in your cart
                            </div>
                        </Col>

                        <Col span={2}>
                            <Divider className="cartVerticalDivider" type="vertical" />
                        </Col>

                        <Col span={11}>
                            <div>Total ${productPriceArray.reduce((a, b) => a + b, 0)}</div>
                        </Col>
                    </Row>
                    <div>
                        <a href="/cart-checkout" ><Button className="cartCheckoutBtn">Checkout</Button></a>
                    </div>
                    <div>
                        <Button className="cartCheckoutBtn" onClick={clearCartItems}>Clear Cart</Button>
                    </div>
                </Menu.Item>
            </Menu>
        );

    }else{
        return(
            <div>
                no items
            </div>
        )
    }
    };

    return (
        <span className="cartBox">
            <Dropdown
                arrow={true}
                overlay={cartProductList}
                // onVisibleChange={onCartDropDownTrriged}
                trigger={["click"]}
            >
                <a
                    className="ant-dropdown-link"
                    onClick={(e) => e.preventDefault()}
                >
                    <div className="cart">
                        <span className="cartCount">{productList  ?  productList.length : 0}</span>
                        <ShoppingCartOutlined />
                        <span className="">
                            Shopping Cart{" "}
                            <DownOutlined />
                        </span>
                    </div>
                </a>
            </Dropdown>
        </span>
    )

}

export default Cart;
