import React, { Component } from "react";
/* We want to import our 'AuthHelperMethods' component in order to send a login request */

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

class About extends Component {

    /* In order to utilize our authentication methods within the AuthService class, we want to instantiate a new object */
    constructor(props) {
        super(props);
        this.state = {

        };
    }


    componentWillMount() {
        /* Here is a great place to redirect someone who is already logged in to the protected route */

    }

    render() {
        return (
            <React.Fragment>
                <Backdrop className="backDropLoader" open={this.state.isLoaderOpen} >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <section class="benifit-section">
                    <div class="container">
                        <div class="row align-items-center">
                            <div class="col-lg-6">
                                <div class="section-title">
                                    <h2>About us</h2>
                                    <p>BounceMania is a full service bounce company providing an array of bounce houses and other outdoor fun suitable for all events from schools, churches, and backyard birthdays to large scale community events. Born out of a need for more safe options for kids to just be kids and enjoy the simpler things in life. We pride ourselves on providing quality service at affordable prices. Our goal is to treat your special event like its ours. We provide a level of professionalism that is unmatched in our industry and still find time to have fun. When you book with us you can expect our quality guarantee: we show up at the time you scheduled us to be there, set up your equipment, and go over safety rules with the host. We know that your time is valuable so we set up and break down within the timeframe you request.</p>
                                    <a href="/contact-us" title="" class="btn-default">Contact Us <i class="fa fa-long-arrow-alt-right"></i></a>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="about-us-section p-0">
                                    <div class="about-sec">
                                        <div class="row">
                                            <div class="col-lg-6 col-md-6 col-sm-6">
                                                <div class="abt-col">
                                                    <img src="/pageImages/icon5.png" alt="" />
                                                    <h3>Happy Clients</h3>
                                                    <p>5041</p>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-6">
                                                <div class="abt-col">
                                                    <img src="/pageImages/icon7.png" alt="" />
                                                    <h3>Members</h3>
                                                    <p>2084</p>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-6">
                                                <div class="abt-col">
                                                    <img src="/pageImages/icon9.png" alt="" />
                                                    <h3>Our Followers</h3>
                                                    <p>634+</p>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-6">
                                                <div class="abt-col">
                                                    <img src="/pageImages/icon8.png" alt="" />
                                                    <h3>Staffs</h3>
                                                    <p>68+</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>




                <section class="newsletter-section">
                    <div class="container">
                        <div class="newsletter-sec">
                            <div class="row align-items-center">
                                <div class="col-lg-12">
                                    <div class="newsz-ltr-text">
                                        <h2>Crowned the Most Safe and Fun Experience Ever! Bring Fun Life To Your Kids</h2>
                                        <a href="/inventory" title="" class="btn-default">Book Now <i class="fa fa-long-arrow-alt-right"></i></a>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>


            </React.Fragment>
        );
    }

}

export default About;