import React, { Component } from "react";
/* We want to import our 'AuthHelperMethods' component in order to send a login request */

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Select } from "@material-ui/core";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import DirectionsCarFilledIcon from '@mui/icons-material/DirectionsCarFilled';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import PersonIcon from '@mui/icons-material/Person';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import MapIcon from '@mui/icons-material/Map';
import BookOnlineIcon from '@mui/icons-material/BookOnline';
import CarRentalIcon from '@mui/icons-material/CarRental';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import { GiConsoleController } from "react-icons/gi";
import {
    Document,
    Page,
    Text,
    View,
    StyleSheet,
    PDFViewer,
} from "@react-pdf/renderer";
import invoicedata from './InvoiceData';

class pdfDownload extends Component {

    /* In order to utilize our authentication methods within the AuthService class, we want to instantiate a new object */
    constructor(props) {
        super(props);
        this.state = {
            sessionId: 0,
            isLoaderOpen: true,
            apiUrl: process.env.REACT_APP_API_URL,
            orderData: {},
            productsData: [],
        };
        const windowUrl = window.location.search;
        const useQuery = () => new URLSearchParams(windowUrl);
        const query = useQuery();
        const sessionId = query.get('session_id');
        const getID = query.get('getID');
        this.setState({ sessionId: sessionId })
        this.fetchCheckoutDet(sessionId, getID);
    }

    fetchCheckoutDet = async (session_id, getID) => {
        fetch(`${this.state.apiUrl}/stripe/stripe_success.php`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                session_id, getID
            })
        })
        .then(res => res.json())
        .then(json => {
            this.setState({
                isLoaderOpen: false,
                productsData: json.data,
                orderData: json.orderData
            })
        }).catch(function() {
            alert('Something went Wrong!, Please contact us.')
            //window.location.href = "/home";
            // this.setState({
            //     isLoaderOpen: false })
        });
    }



    render() {
        const { orderData, productsData, sessionId, getID } = this.state;
        // Create styles
        const styles = StyleSheet.create({
            page: {
                backgroundColor: "#d11fb6",
                color: "white",
            },
            section: {
                margin: 10,
                padding: 10,
            },
            viewer: {
                width: window.innerWidth, //the pdf viewer will take up all of the width and height
                height: window.innerHeight,
            },
            titleContainer: {
                marginTop: 12
            },
            reportTitle: {
                fontSize: 12,
                textAlign: 'center',
                textTransform: 'uppercase',
            },
            row: {
                flexDirection: 'row',
                borderBottomColor: '#3778C2',
                borderBottomWidth: 1,
                alignItems: 'center',
                height: 24,
                fontSize: 12,
                fontStyle: 'bold',
            },
            description: {
                width: '85%',
                textAlign: 'right',
                borderRightColor: '#000',
                borderRightWidth: 1,
                paddingRight: 8,
            },
            total: {
                width: '15%',
                textAlign: 'right',
                paddingRight: 8,
            },
            invoiceTableRow: {
                flexDirection: 'row',
                borderBottomColor: '#3778C2',
                borderBottomWidth: 1,
                alignItems: 'center',
                height: 24,
                fontStyle: 'bold',
            },
            description: {
                width: '60%',
                textAlign: 'left',
                borderRightColor: '#000',
                borderRightWidth: 1,
                paddingLeft: 8,
            },
            qty: {
                width: '10%',
                borderRightColor: '#000',
                borderRightWidth: 1,
                textAlign: 'right',
                paddingRight: 8,
            },
            rate: {
                width: '15%',
                borderRightColor: '#000',
                borderRightWidth: 1,
                textAlign: 'right',
                paddingRight: 8,
            },
            amount: {
                width: '15%',
                textAlign: 'right',
                paddingRight: 8,
            },
            container: {
                flexDirection: 'row',
                borderBottomColor: '#3778C2',
                backgroundColor: '#3778C2',
                color: '#fff',
                borderBottomWidth: 1,
                alignItems: 'center',
                height: 24,
                textAlign: 'center',
                fontStyle: 'bold',
                flexGrow: 1,
            },
            description: {
                width: '60%',
                borderRightColor: '#000',
                borderRightWidth: 1,
            },
            qty: {
                width: '10%',
                borderRightColor: '#000',
                borderRightWidth: 1,
            },
            rate: {
                width: '15%',
                borderRightColor: '#000',
                borderRightWidth: 1,
            },
            amount: {
                width: '15%'
            },
            tableContainer: {
                flexDirection: 'row',
                flexWrap: 'wrap',
                marginTop: 24,
                borderWidth: 1,
                borderColor: '#3778C2',
            },
            headerContainer: {
                marginTop: 36,
                justifyContent: 'flex-start',
                width: '50%'
            },
            billTo: {
                marginTop: 20,
                paddingBottom: 3,
                fontFamily: 'Helvetica-Oblique'
            },
            invoiceNoContainer: {
                flexDirection: 'row',
                marginTop: 36,
                justifyContent: 'flex-end'
            },
            invoiceDateContainer: {
                flexDirection: 'row',
                justifyContent: 'flex-end'
            },
            invoiceDate: {
                fontSize: 12,
                fontStyle: 'bold',
            },
            label: {
                width: 60
            },
            titleContainer: {
                marginTop: 24,
            },
            reportTitle: {
                color: '#3778C2',
                letterSpacing: 4,
                fontSize: 25,
                textAlign: 'center',
                textTransform: 'uppercase',
            },
            page: {
                backgroundColor: '#fff',
                fontFamily: 'Helvetica',
                fontSize: 11,
                paddingTop: 30,
                paddingLeft: 50,
                paddingRight: 50,
                lineHeight: 1.5,
                flexDirection: 'column',
            },
            logo: {
                width: 84,
                height: 70,
                marginLeft: 'auto',
                marginRight: 'auto'
            }
        });
        return (
            <React.Fragment>
                <Backdrop className="backDropLoader" open={this.state.isLoaderOpen} style={{ zIndex: '9999' }} >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <div>
                    <PDFViewer style={styles.viewer}>
                        {/* Start of the document*/}
                        <Document>
                            {/*render a single page*/}
                            <Page size="A4" style={styles.page} >
                                {/* <Image style={styles.logo} src={logo} /> */}
                                <View style={styles.titleContainer}>
                                    <Text style={styles.reportTitle}>Invoice</Text>
                                </View>
                                <View style={styles.invoiceNoContainer}>
                                    <Text style={styles.label}>Invoice No:</Text>
                                    <Text style={styles.invoiceDate}>000{orderData.id}</Text>
                                </View >
                                <View style={styles.invoiceDateContainer}>
                                    <Text style={styles.label}>Date: </Text>
                                    <Text >{orderData.created_at}</Text>
                                </View >
                                <View style={styles.headerContainer}>
                                    <Text style={styles.billTo}>Bill To:</Text>
                                    <Text>{orderData.first_name} {orderData.last_name}</Text>
                                    <Text>{orderData.address}</Text>
                                    <Text>{orderData.phone}</Text>
                                    <Text>{orderData.email}</Text>
                                </View>
                                <View style={styles.tableContainer}>
                                    <View style={styles.container}>
                                        <Text style={styles.description}>Item Description</Text>
                                        <Text style={styles.qty}>Qty</Text>
                                        <Text style={styles.rate}>Price</Text>
                                        <Text style={styles.amount}>Amount</Text>
                                    </View>
                                    {productsData.map((item, idx)=>{
                                        return <View style={styles.invoiceTableRow} key={idx}>
                                        <Text style={styles.description}>{item.product_name}</Text>
                                        <Text style={styles.qty}>1</Text>
                                        <Text style={styles.rate}>{item.product_cost}</Text>
                                        <Text style={styles.amount}>{(1 * item.product_cost).toFixed(2)}</Text>
                                    </View>
                                    })}
                                    
                                    <View style={styles.row}>
                                        <Text style={styles.description}>TOTAL</Text>
                                        <Text style={styles.total}>{Number.parseFloat(orderData.paid_amount).toFixed(2)}</Text>
                                    </View>
                                </View>
                                <View style={styles.titleContainer}>
                                    <Text style={styles.reportTitle}>*** Thank You ***</Text>
                                </View>
                            </Page>
                        </Document>
                    </PDFViewer>
                    sdfsdfsd

                </div>
            </React.Fragment>
        );
    }

}

export default pdfDownload;